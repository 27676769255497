import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import 'ol/ol.css';
import Draw from 'ol/interaction/Draw';
import Circle from 'ol/geom/Circle';
import Polygon from 'ol/geom/Polygon'
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point'
import GeoJSON from 'ol/format/GeoJSON';
import { fromLonLat } from 'ol/proj';
import Map from 'ol/Map';
import View from 'ol/View';
import { Circle as CircleStyle, Fill, Stroke, Style, RegularShape } from 'ol/style';
import { OSM, Vector as VectorSource } from 'ol/source';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import axios from 'axios'
import {requestUrl} from '../constants/globalConstants'
import LinearRing from 'ol/geom/LinearRing';
import '../css/project.css'
import BubleModal from './BubleModal'




export default function Mapa(props) {


  const [coords, setCoords] = useState([])
  const [show, setShow] = useState("none")
  const [currentImage, setCurrentImage] = useState("")
  const [loadedImage, setLoadedImage] = useState(false)
  const [currentLink, setCurrentLink] = useState("")
  const [test, setTest] = useState(false)
  const [showBubbleModal, setShowBubbleModal] = useState(false)
  const [type, setType] = useState()
  const [title, setTitle] = useState()

  const userLogin = useSelector(state => state.userLogin)
  const { userInfo } = userLogin

  async function deletePanorama(){
    const config = {
      headers: {

          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`

      }
    }

    const {data} = await axios.post(requestUrl() + 'api/deletepanorama/', {"user_id": userInfo.user_id, "pano_id": currentImage.attributes.pano_id}, config)
    window.location.reload()
  }


  useEffect(() => {
    var image = new CircleStyle({
      radius: 5,
      fill: null,
      stroke: new Stroke({ color: 'red', width: 1 }),
    });

    var styles = {
      'Point': new Style({
        image: image,
      }),
      'LineString': new Style({
        stroke: new Stroke({
          color: 'green',
          width: 1,
        }),
      }),
      'MultiLineString': new Style({
        stroke: new Stroke({
          color: 'green',
          width: 1,
        }),
      }),
      'MultiPoint': new Style({
        image: image,
      }),
      'MultiPolygon': new Style({
        stroke: new Stroke({
          color: 'yellow',
          width: 1,
        }),
        fill: new Fill({
          color: 'rgba(255, 255, 0, 0.1)',
        }),
      }),
      'Polygon': new Style({
        stroke: new Stroke({
          color: 'blue',
          lineDash: [4],
          width: 3,
        }),
        fill: new Fill({
          color: 'rgba(0, 0, 255, 0.1)',
        }),
      }),
      'GeometryCollection': new Style({
        stroke: new Stroke({
          color: 'magenta',
          width: 2,
        }),
        fill: new Fill({
          color: 'magenta',
        }),
        image: new CircleStyle({
          radius: 10,
          fill: null,
          stroke: new Stroke({
            color: 'magenta',
          }),
        }),
      }),
      'Circle': new Style({
        stroke: new Stroke({
          color: 'red',
          width: 10,
        }),
        fill: new Fill({
          color: 'rgba(255,0,0,0.2)',
        }),
      }),
      'Square': new Style({
        image: new RegularShape({
          fill: new Fill({
            color: 'blue',
          }),
          stroke: new Stroke({
            color: 'red',
            width: 10,
          }),
          points: 4,
          radius: 10,
          angle: Math.PI / 4,
        }),
      }),
    };

    var styleFunction = function (feature) {
      return styles[feature.getGeometry().getType()];
    };

    var geojsonObject = props.geojson

    var vectorSource = new VectorSource({
      features: (new GeoJSON({
        featureProjection: 'EPSG:3857',
        dataProjection: 'EPSG:4326'
      })).readFeatures(geojsonObject)
    });

    if(props.markers){
      let images = {}
      for (let i = 0; i < props.markers.pano.length; i++) {
        let feature = new Feature(new Circle(fromLonLat([props.markers.pano[i].pano_x, props.markers.pano[i].pano_y])))
        // promeniti slice kada se prebaci na server
        let image_path = props.markers.pano[i].pano_is_connected ? "https://potree.geotaur.xyz/" + props.markers.pano[i].pano_image.slice(24) : "https://portal.geotaur.xyz:8000/media/" + props.markers.pano[i].pano_image.slice(46)
        feature.attributes = { "image_path" : image_path, "image_name" : props.markers.pano[i].pano_image.split("/")[props.markers.pano[i].pano_image.split("/").length-1], "potree_link" : props.markers.og[i].pano_potree_link, "pano_id": props.markers.pano[i].pano_id, "type": "pano"}
        vectorSource.addFeature(feature)
      }

      var drawImage = new CircleStyle({
        radius: 5,
        fill: new Fill({
          color: 'rgba(0,0,255, 1)',
        }),
        stroke: new Stroke({ color: 'blue', width: 1 }),
      });

      for(let i = 0; i < props.project.tours.length; i++){
        let coords = props.project.tours[i].coords.split(",");
        let feature = new Feature(new Circle([parseFloat(coords[0]), parseFloat(coords[1])]));
        feature.attributes = {type: "tour", name: props.project.tours[i].name, link: props.project.tours[i].link}
        feature.setStyle(new Style({
          stroke: new Stroke({
            color: 'blue',
            width: 10,
          }),
          fill: new Fill({
            color: 'rgba(0,0,255,0.2)',
          }),
        }))
        vectorSource.addFeature(feature);

      }
    }
    
    const ptSource = new VectorSource({
      features: [],
    });

    for (let i = 0; i < props.project.point_clouds.length; i++){

      let ring = [fromLonLat([props.project.point_clouds[i].x_min, props.project.point_clouds[i].y_min]), fromLonLat([props.project.point_clouds[i].x_min, props.project.point_clouds[i].y_max]), fromLonLat([props.project.point_clouds[i].x_max, props.project.point_clouds[i].y_max]), fromLonLat([props.project.point_clouds[i].x_max, props.project.point_clouds[i].y_min]), fromLonLat([props.project.point_clouds[i].x_min, props.project.point_clouds[i].y_min])]

      var polygon = new Polygon([ring]);

      let feature = new Feature(polygon)

      feature.attributes = {type: "pointcloud", potreeLink: props.project.point_clouds[i].potree_link, name: props.project.point_clouds[i].name}

      ptSource.addFeature(feature)
    }
    const ptVectorLayer = new VectorLayer({
      source: ptSource,
    });
    ptVectorLayer.setZIndex(3)

    var vectorLayer = new VectorLayer({
      source: vectorSource,
      style: styleFunction,
    });
    vectorLayer.setZIndex(0)
    

    var map = new Map({
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
        vectorLayer,
        ptVectorLayer],
      target: 'map',
      view: new View({
        center: [0, 0],
        zoom: 2,
      }),
    });
    
    if(props.isDrawing){
      let draw; // global so we can remove it later
      function addInteraction() {
        const value = "Point";
        console.log(props.value)
        if (value !== 'None') {
          draw = new Draw({
            source: vectorSource,
            type: value,
            element: "drawing",
          });

          draw.on('drawend', function(e) {
            //disableDrawing()
            setTest(false);
            //props.callback();
            console.log(e.feature, e.feature.getProperties());
          });

          let i = map.getInteractions(draw);

          map.addInteraction(i);
        }
      }
      addInteraction();
    }

    map.on("pointermove", function (evt) {
      var hit = this.forEachFeatureAtPixel(evt.pixel, function(feature, layer) {
          return true;
      }); 
      if (hit) {
          this.getTargetElement().style.cursor = 'pointer';
      } else {
          this.getTargetElement().style.cursor = '';
      }
    });

    if(!props.isDrawing){
      map.on("click", function (e) {
        map.forEachFeatureAtPixel(e.pixel, function (feature, layer) {
          if(feature.attributes && Object.keys(feature.attributes).includes("type") && feature.attributes.type === "pano"){
            setCurrentImage(feature)
            setTitle(feature.attributes.image_name)
            setCurrentLink(feature.attributes.potree_link)
            setType("pano")
            setShowBubbleModal(true)             
            window.addEventListener('mousemove', function (e) {
              setCoords([e.x, e.y])
            }, {once: true})
            var currZoom = map.getView().getZoom();
            map.on('moveend', function(e) {
              var newZoom = map.getView().getZoom();
              
              console.log('zoom end, new zoom: ' + newZoom);
              currZoom = newZoom;
              //var geometry = feature.getGeometry();
              var coordinate = feature.getGeometry().getCoordinates();
              if(coordinate){
                var pixel = map.getPixelFromCoordinate(coordinate);
                console.log("radi")
                setCoords(pixel)
              }
            
            });
          }
          else if(feature.attributes && Object.keys(feature.attributes).includes("type") && feature.attributes.type === "pointcloud"){
            setType("pointcloud")
            setTitle(feature.attributes.name)
            setCurrentLink(feature.attributes.potreeLink)
            setShowBubbleModal(true)
          }
          else if(feature.attributes && Object.keys(feature.attributes).includes("type") && feature.attributes.type === "tour"){
            setType("tour")
            setTitle(feature.attributes.name)
            setCurrentLink(feature.attributes.link)
            setShowBubbleModal(true)
          }
        },)
      });
    }
  }, [props, test])
  return (
    <div style={{ overflow: "hidden"}}>
      <div id="map" className="map" style={{ height: "100vh", width: "100vw", overflow: "hidden" }}>
      </div>
      {type &&
        <BubleModal
        type={type}
        show={showBubbleModal}
        onHide={() => setShowBubbleModal(false)}
        title={title}
        imagepath={type === "pano" ? currentImage.attributes.image_path : ""}
        potreeLink={currentLink}
        deletePanorama={deletePanorama}
        />
      }
    </div>
  )


}
