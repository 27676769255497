import {useState, useEffect} from 'react'
import axios from 'axios'
import { requestUrl } from "../constants/globalConstants"
import { Modal, Button, Form, FormGroup, ProgressBar, Tabs, Tab, Badge } from "react-bootstrap"
import {useSelector} from 'react-redux'
import CustomSelect from "./CustomSelect"

function UploadModal(props) {
    const [uploadProgress, setUploadProgress] = useState(0)
    const [uploading, setUploading] = useState(false)
    const [key, setKey] = useState('pano');
    const [project, setProject] = useState()
    const [clouds, setClouds] = useState([])

    const userLogin = useSelector(state => state.userLogin)

    const { userInfo } = userLogin;

    async function getProjectInfo() {

        const config = {
            headers: {

                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`

            }

        }

        const { data } = await axios.get(requestUrl() + 'api/getprojectinfo/?project_id=' + props.project_id + '&user_id=' + userInfo.user_id + '&token=' + userInfo.token)
        setProject(data)
    }

    async function getProject(){
        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }
        const { data } = await axios.get(requestUrl() + 'api/getproject/?user_id=' + userInfo.user_id + '&project_id=' + props.project_id + '&token=' + userInfo.token, config)
    }

    useEffect(()=>{
        getProject()
        getProjectInfo()

    }, [])

    function callbackFunction(state, cloud) {
        if (state){
            var newArray = clouds; 
            newArray.push(cloud.id); 
            setClouds(newArray)
        } else {
            var newArray = clouds; 
            newArray.splice(newArray.indexOf(cloud.id), 1); 
            setClouds(newArray)
        }; 
        console.log(clouds)
    }


    const uploadFileHandler = async (e, type) => {
        console.log(type)
        if (type === "pano") {

            const file = e.target.files[0]

            let formData = new FormData()

            formData.append('file', file)
            //formData.append('project_id', project.project_id)
            formData.append('project_id', props.project_id)
            formData.append('user_id', userInfo.user_id)

            try {

                const config = {

                    headers: {

                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${userInfo.token}`

                    },
                    onUploadProgress: progressEvent => {
                        let percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
                        setUploadProgress(percentCompleted)

                    }

                }

                setUploading(true)
                
                const { data } = await axios.post(requestUrl() + 'api/uploadpanoramas/', formData, config)

                window.location.reload()
                
                
            } catch (e){

            }

            

        } else if (type === "las") {
            console.log("usao")
            const file = e.target.files[0]

            let formData = new FormData()

            formData.append('file', file)
            formData.append('project_id', props.project_id)
            formData.append('user_id', userInfo.user_id)

            try {

                const config = {

                    headers: {

                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${userInfo.token}`

                    },
                    onUploadProgress: progressEvent => {
                        let percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
                        setUploadProgress(percentCompleted)

                    }

                }

                setUploading(true)
                const { data } = await axios.post(requestUrl() + 'api/uploadlas/', formData, config)

                window.location.reload()
                
                
            } catch (e) {

            } 

        } else if (type === "3d") {
            const file = e.target.files[0]

            let formData = new FormData()

            formData.append('file', file)
            formData.append('project_id', props.project_id)
            formData.append('user_id', userInfo.user_id)
            formData.append('clouds', clouds)

            try {

                const config = {

                    headers: {

                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${userInfo.token}`

                    },
                    onUploadProgress: progressEvent => {
                        let percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
                        setUploadProgress(percentCompleted)

                    }

                }

                setUploading(true)
                const { data } = await axios.post(requestUrl() + 'api/uploadlas/', formData, config)

                window.location.reload()
                

            } catch (e) {

            } 
        }

        setUploading(false)
    }
    return (
        <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        >
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
            Upload Assets
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Tabs
                activeKey={key}
                onSelect={(k) => setKey(k)}
            >
                <Tab eventKey="pano" title="Panoramas">
                    <Form>
                        <FormGroup style={{textAlign: "center"}}>
                            <p>To upload you need to put your panoramas in an archive (.zip, .rar, etc.) alongside your .txt. file that must contain metadata about panoramas. All panoramas should be uniquely named and stored in one archive outside of any folders. 
                                Example of .txt file can be found <a href="https://portal.geotaur.xyz:8000/media/uputstvo2.jpg" target="_blank">here.</a></p>
                            <br></br>
                            <label for="file-upload-pano" class="btn btn-primary" style={{width: "13rem"}}>UPLOAD PANO</label>
                            <input id="file-upload-pano" type="file" onChange={(e)=> {uploadFileHandler(e, "pano")}}/>
                            {uploading &&
                                <div>
                                    <br></br>
                                    <ProgressBar animated now={uploadProgress} label={`${uploadProgress}%`} />
                                </div>     
                            }
                        </FormGroup>
                    </Form>
                </Tab>
                <Tab eventKey="las" title="PointClouds">
                    <Form>
                        <FormGroup style={{textAlign: "center"}}>
                            <p>To upload point clouds, you have to archive (.zip, .rar, etc.) your list of point clouds. They should all be named uniquely and stored outside of any folders.
                                Acceptable point cloud format is .las.
                            </p>
                            <br></br>
                            <label for="file-upload-las" class="btn btn-primary" style={{width: "13rem"}}>UPLOAD POINTCLOUD</label>
                            <input id="file-upload-las" type="file" onChange={(e)=> {uploadFileHandler(e, "las")}}/>
                            {uploading &&
                                <div>
                                    <br></br>
                                    <ProgressBar animated now={uploadProgress} label={`${uploadProgress}%`} />
                                </div>     
                            }
                        </FormGroup>
                    </Form>
                </Tab>
                <Tab eventKey="3d" title="3D Models">
                    <Form>
                        <FormGroup style={{textAlign: "center"}}>
                            <p>To upload 3D models, you have to archive (.zip, .rar, etc.) your list of point clouds. They should all be named uniquely and stored outside of any folders.
                                Acceptable point cloud format is .las.
                            </p>
                            <br></br>
                            <FormGroup>
                                {project && project.point_clouds.map(e => {
                                    return(
                                        <CustomSelect name={e.name} callback={callbackFunction} cloud={e}></CustomSelect>
                                    )
                                })}
                            </FormGroup>
                            <br></br>
                            <label for="file-upload-las" class="btn btn-primary" style={{width: "13rem"}}>UPLOAD 3D Models</label>
                            <input id="file-upload-las" type="file" onChange={(e)=> {uploadFileHandler(e, "3d")}}/>
                            {uploading &&
                                <div>
                                    <br></br>
                                    <ProgressBar animated now={uploadProgress} label={`${uploadProgress}%`} />
                                </div>     
                            }
                        </FormGroup>
                    </Form>
                </Tab>
            </Tabs>
        </Modal.Body>
        </Modal>
    )
}

export default UploadModal
