import { useState, useEffect } from 'react'
import '../index.css'
import Mapa from '../components/Mapa'
import UploadModal from '../components/UploadModal'
import {requestUrl} from '../constants/globalConstants'
import { Button } from 'react-bootstrap'


function TestScreen() {
    const [modalShow, setModalShow] = useState(false)

    function finishUpload() {
        setModalShow(false)
    }

    return (
      <div className="h-100">
        <Mapa geojson={{
            "type": "FeatureCollection",
            "features": [
                {
                    "type": "Feature",
                    "properties": {},
                    "geometry": {
                        "type": "Polygon",
                        "coordinates": [
                            [
                                [
                                    0,0
                                ],
                                [
                                    0,0
                                ],
                                [
                                    0,0
                                ],
                                [
                                    0,0
                                ],
                                [
                                    0,0
                                ]
                            ]
                        ]
                    }
                }
            ]
        }} markers={{
          "pano": [
            {
                "id": 16,
                "x": 50.00585406598468,
                "y": 26.48683607122845,
                "z": -17.904,
                "oblak_tacaka_id": 2
            },
            {
                "id": 17,
                "x": 50.00589328281786,
                "y": 26.486888873158392,
                "z": -17.908,
                "oblak_tacaka_id": 2
            },
            {
                "id": 18,
                "x": 50.00585406598468,
                "y": 26.48683607122845,
                "z": -17.904,
                "oblak_tacaka_id": 3
            },
            {
                "id": 19,
                "x": 50.00589328281786,
                "y": 26.486888873158392,
                "z": -17.908,
                "oblak_tacaka_id": 3
            },
            {
                "id": 20,
                "x": 50.00585406598468,
                "y": 26.48683607122845,
                "z": -17.904,
                "oblak_tacaka_id": 4
            },
            {
                "id": 21,
                "x": 50.00589328281786,
                "y": 26.486888873158392,
                "z": -17.908,
                "oblak_tacaka_id": 4
            },
            {
                "id": 22,
                "x": 50.00585406598468,
                "y": 26.48683607122845,
                "z": -17.904,
                "oblak_tacaka_id": 5
            },
            {
                "id": 23,
                "x": 50.00589328281786,
                "y": 26.486888873158392,
                "z": -17.908,
                "oblak_tacaka_id": 5
            },
            {
                "id": 24,
                "x": 50.00585406598468,
                "y": 26.48683607122845,
                "z": -17.904,
                "oblak_tacaka_id": 6
            },
            {
                "id": 25,
                "x": 50.00589328281786,
                "y": 26.486888873158392,
                "z": -17.908,
                "oblak_tacaka_id": 6
            },
            {
                "id": 26,
                "x": 50.00585406598468,
                "y": 26.48683607122845,
                "z": -17.904,
                "oblak_tacaka_id": 7
            },
            {
                "id": 27,
                "x": 50.00589328281786,
                "y": 26.486888873158392,
                "z": -17.908,
                "oblak_tacaka_id": 7
            },
            {
                "id": 28,
                "x": 50.00585406598468,
                "y": 26.48683607122845,
                "z": -17.904,
                "oblak_tacaka_id": 8
            },
            {
                "id": 29,
                "x": 50.00589328281786,
                "y": 26.486888873158392,
                "z": -17.908,
                "oblak_tacaka_id": 8
            },
            {
                "id": 30,
                "x": 50.00585406598468,
                "y": 26.48683607122845,
                "z": -17.904,
                "oblak_tacaka_id": 9
            },
            {
                "id": 31,
                "x": 50.00589328281786,
                "y": 26.486888873158392,
                "z": -17.908,
                "oblak_tacaka_id": 9
            },
            {
                "id": 32,
                "x": 50.00585406598468,
                "y": 26.48683607122845,
                "z": -17.904,
                "oblak_tacaka_id": 10
            },
            {
                "id": 33,
                "x": 50.00589328281786,
                "y": 26.486888873158392,
                "z": -17.908,
                "oblak_tacaka_id": 10
            }
        ]
        }}></Mapa>
        <Button variant="primary" onClick={()=>setModalShow(true)} style= {{position: "absolute", top: "10%", left: "90%"}}>Upload Assets</Button>
        <UploadModal
        show={modalShow}
        onHide={() => finishUpload()}
        ></UploadModal>
    </div>
    )
}

export default TestScreen
