import React, { useEffect } from 'react'
import 'ol/ol.css';
import Draw from 'ol/interaction/Draw';
import GeoJSON from 'ol/format/GeoJSON';
import Map from 'ol/Map';
import View from 'ol/View';
import { Circle as CircleStyle, Fill, Stroke, Style, RegularShape } from 'ol/style';
import { OSM, Vector as VectorSource } from 'ol/source';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import Circle from 'ol/geom/Circle';
import Polygon from 'ol/geom/Polygon'
import Feature from 'ol/Feature';
import { fromLonLat } from 'ol/proj';
import { getMapboxPath } from 'ol/layer/MapboxVector';


function SmallMap(props) {
    useEffect(() => {
        var image = new CircleStyle({
          radius: 5,
          fill: null,
          stroke: new Stroke({ color: 'red', width: 1 }),
        });
        
        var drawImage = new CircleStyle({
          radius: 5,
          fill: new Fill({
            color: 'rgba(0,0,255, 1)',
          }),
          stroke: new Stroke({ color: 'blue', width: 1 }),
        });
    
        var styles = {
            'Point': new Style({
              image: image,
            }),
            'LineString': new Style({
              stroke: new Stroke({
                color: 'green',
                width: 1,
              }),
            }),
            'MultiLineString': new Style({
              stroke: new Stroke({
                color: 'green',
                width: 1,
              }),
            }),
            'MultiPoint': new Style({
              image: image,
            }),
            'MultiPolygon': new Style({
              stroke: new Stroke({
                color: 'yellow',
                width: 1,
              }),
              fill: new Fill({
                color: 'rgba(255, 255, 0, 0.1)',
              }),
            }),
            'Polygon': new Style({
              stroke: new Stroke({
                color: 'blue',
                lineDash: [4],
                width: 3,
              }),
              fill: new Fill({
                color: 'rgba(0, 0, 255, 0.1)',
              }),
            }),
            'GeometryCollection': new Style({
              stroke: new Stroke({
                color: 'magenta',
                width: 2,
              }),
              fill: new Fill({
                color: 'magenta',
              }),
              image: new CircleStyle({
                radius: 10,
                fill: null,
                stroke: new Stroke({
                  color: 'magenta',
                }),
              }),
            }),
            'Circle': new Style({
              stroke: new Stroke({
                color: 'red',
                width: 10,
              }),
              fill: new Fill({
                color: 'rgba(255,0,0,0.2)',
              }),
            }),
            'Square': new Style({
              image: new RegularShape({
                fill: new Fill({
                  color: 'blue',
                }),
                stroke: new Stroke({
                  color: 'red',
                  width: 10,
                }),
                points: 4,
                radius: 10,
                angle: Math.PI / 4,
              }),
            }),
          };

        var drawingStyles = {
          'Point': new Style({
            image: drawImage,
          }),
        }
    
        var styleFunction = function (feature) {
          return styles[feature.getGeometry().getType()];
        };

        var drawingStyleFunction = function (feature){
          return drawingStyles[feature.getGeometry().getType()];
        }
    
        var geojsonObject = {
            "type": "FeatureCollection",
            "features": [
                {
                    "type": "Feature",
                    "properties": {},
                    "geometry": {
                        "type": "Polygon",
                        "coordinates": [
                            [
                                [
                                    0,0
                                ],
                                [
                                    0,0
                                ],
                                [
                                    0,0
                                ],
                                [
                                    0,0
                                ],
                                [
                                    0,0
                                ]
                            ]
                        ]
                    }
                }
            ]
        }

        var vectorSource = new VectorSource({
          features: (new GeoJSON({
            featureProjection: 'EPSG:3857',
            dataProjection: 'EPSG:4326'
          })).readFeatures(geojsonObject)
        });

        var drawingVectorSource = new VectorSource({
          features: (new GeoJSON({
            featureProjection: 'EPSG:3857',
            dataProjection: 'EPSG:4326'
          })).readFeatures(geojsonObject)
        });

        if(props.markers){
            let images = {}
            for (let i = 0; i < props.markers.pano.length; i++) {
              let feature = new Feature(new Circle(fromLonLat([props.markers.pano[i].pano_x, props.markers.pano[i].pano_y])))
              // promeniti slice kada se prebaci na server
              let image_path = props.markers.pano[i].pano_is_connected ? "https://potree.geotaur.xyz/" + props.markers.pano[i].pano_image.slice(21) : "https://portal.geotaur.xyz:8000/media/" + props.markers.pano[i].pano_image.slice(47)
              feature.attributes = { "image_path" : image_path, "image_name" : props.markers.pano[i].pano_image.split("/")[props.markers.pano[i].pano_image.split("/").length-1], "potree_link" : props.markers.og[i].pano_potree_link, "pano_id": props.markers.pano[i].pano_id, "type": "pano"}
              vectorSource.addFeature(feature)
            }
          }
          
          for (let i = 0; i < props.project.point_clouds.length; i++){
      
            let ring = [fromLonLat([props.project.point_clouds[i].x_min, props.project.point_clouds[i].y_min]), fromLonLat([props.project.point_clouds[i].x_min, props.project.point_clouds[i].y_max]), fromLonLat([props.project.point_clouds[i].x_max, props.project.point_clouds[i].y_max]), fromLonLat([props.project.point_clouds[i].x_max, props.project.point_clouds[i].y_min]), fromLonLat([props.project.point_clouds[i].x_min, props.project.point_clouds[i].y_min])]
      
            var polygon = new Polygon([ring]);
      
            let feature = new Feature(polygon)
      
            vectorSource.addFeature(feature)
          }
    
        var vectorLayer = new VectorLayer({
          source: vectorSource,
          style: styleFunction,
        });

        var drawingVectorLayer = new VectorLayer({
          source: drawingVectorSource,
          style: drawingStyleFunction,
        })
        
    
        var map = new Map({
          layers: [
            new TileLayer({
              source: new OSM(),
            }),
            vectorLayer],
          target: 'small-map',
          view: new View({
            center: [0, 0],
            zoom: 2,
          }),
        });
        
        map.addLayer(drawingVectorLayer);
        let draw; // global so we can remove it later
        function addInteraction() {
            const value = "Point";
            
            if (value !== 'None') {
                draw = new Draw({
                source: drawingVectorSource,
                type: value,
                element: "drawing",
                });

                draw.on('drawend', function(e) {
                    try{
                        var features = drawingVectorSource.getFeatures();
                        var lastFeature = features[features.length - 1];
                        drawingVectorSource.removeFeature(lastFeature);
                        //console.log(e.feature.getGeometry().getCoordinates().toString())
                        props.updateCoords(e.feature.getGeometry().getCoordinates().toString())
                    } catch(e) {}
                });

                map.addInteraction(draw);
            }
        }
        addInteraction();
        
    
        map.on("pointermove", function (evt) {
          var hit = this.forEachFeatureAtPixel(evt.pixel, function(feature, layer) {
              return true;
          }); 
          if (hit) {
              this.getTargetElement().style.cursor = 'pointer';
          } else {
              this.getTargetElement().style.cursor = '';
          }
        });
      }, [])
  return <div>
            <div id="small-map" className="small-map" style={{ height: "300px", width: "100%", overflow: "hidden" }}></div>
        </div>;
}

export default SmallMap;
