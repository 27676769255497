import axios from "axios";
import { requestUrl } from "../constants/globalConstants";
import { Modal, Button } from "react-bootstrap";
import {useSelector} from 'react-redux';


export default function DeleteModal(props){
    
    const {loading, error, userInfo} = useSelector(state=>state.userLogin)

    async function deleteProject(userid, id){
        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }
        const {data} = await axios.post(requestUrl() + "api/deleteproject/", {user_id: props.user_id, project_id: props.project_id}, config)
        props.onHide()
    }

    return (
        <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        >
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
            Are you sure you want to delete {props.project_name}?<br></br>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            By deleting this project, you will lose all files, pointclouds and settings related to this project.
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={props.onHide}>Close</Button>
            <Button onClick={deleteProject} variant="danger">Delete Project</Button>
        </Modal.Footer>
        </Modal>
    );


}