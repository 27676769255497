import react, {useState} from 'react';

export default function CustomSelect(props){

    const [borderColor, setBorderColor] = useState("black");
    const [checked, setChecked] = useState(false);
    const [boxColor, setBoxColor] = useState("black");
    const [boxOpacity, setBoxOpacity] = useState(0);



    function changeColor(state){
      if(state){
        setBorderColor("green");
        setBoxColor("green");
        setBoxOpacity(1);
        
      } else{
        setBorderColor("black");
        setBoxColor("black");
        setBoxOpacity(0);
      }
    }

    function customClick(){
      props.callback(!checked, props.cloud);
      changeColor(!checked);
      setChecked(!checked);
      
    }



    return (
    <div class="check" onClick={customClick} style={{borderColor: borderColor}}>
        <label>{props.name}</label>
        <input class="checkbox" type="checkbox" onChange={changeColor} onClick={customClick}/><span class="custom-box" style={{backgroundColor: boxColor}}><i class="icon-check" class="fas fa-check" style={{opacity: boxOpacity, color: "white"}}></i></span>
    </div>
  );

}