import { Modal, Button, Form, FormGroup, Alert } from "react-bootstrap"
import {useSelector} from 'react-redux'
import {useState, useEffect} from 'react'
import axios from 'axios'
import { requestUrl, postConfig } from "../constants/globalConstants"
import SmallMap from "./SmallMap";

export default function CreateTourModal(props){
    const [name, setName] = useState("")
    const [link, setLink] = useState()
    const [coords, setCoords] = useState();
    const [error, setError] = useState();

    const userLogin = useSelector(state => state.userLogin)

    const { userInfo } = userLogin;


    function updateCoords(coords){
        console.log(coords);
        setCoords(coords);
    }

    async function createTour(){
        try{
            const {data} = await axios.post(requestUrl() + "api/createtour/", {name: name, link: link, coords: coords, project_id: props.project.project.project_id, user_id: userInfo.user_id}, postConfig(userInfo));
            window.location.reload();
        } catch(err){
            setError(err.response.data);
        }

    }


    return(
        <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        >
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
            Create Tour
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {error && <Alert variant="danger">{error}</Alert>}
            <Form>
                <Form.Group>
                    <Form.Label>Tour name</Form.Label>
                    <Form.Control type="text" onChange={(e)=>setName(e.target.value)}/>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Tour link</Form.Label>
                    <Form.Control type="text" onChange={(e)=>setLink(e.target.value)}/>
                </Form.Group>
                <Form.Group>
                <SmallMap project={props.project} markers={props.markers} updateCoords={updateCoords}></SmallMap>
                </Form.Group>
            </Form>
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={props.onHide}>Close</Button>
            <Button onClick={createTour}>Create Tour</Button>
        </Modal.Footer>
        </Modal>
    );
};