import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { logout } from '../actions/userActions'

function Header() {

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const dispatch = useDispatch()

    const logoutHandler = () => {
        dispatch(logout())
        //window.location.href = "/login"
        window.location.reload()
    }

    return (
        <header>
            <Navbar bg="dark" variant="dark" expand="lg" collapseOnSelect>
                <Container>
                    <LinkContainer to='/'>
                        <Navbar.Brand>Geotaur</Navbar.Brand>
                    </LinkContainer>

                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav>
                            <LinkContainer to='/abrp'>
                                <Nav.Link>ABRP</Nav.Link>
                            </LinkContainer>
                        </Nav>


                        <Nav className="ml-auto">

                            {userInfo ? (
                                <div>
                                    <NavDropdown title={userInfo.first_name + " " + userInfo.last_name} id='username'>
                                        <LinkContainer to='/profile'>
                                            <NavDropdown.Item>Profile</NavDropdown.Item>
                                        </LinkContainer>

                                        <LinkContainer to='/projects'>
                                            <NavDropdown.Item>Projects</NavDropdown.Item>
                                        </LinkContainer>
                                        {userInfo.role == "admin" && (
                                            <div>
                                                <LinkContainer to='/admin/userlist'>
                                                    <NavDropdown.Item>All Users</NavDropdown.Item>
                                                </LinkContainer>
                                                <LinkContainer to='/register'>
                                                    <NavDropdown.Item>Register</NavDropdown.Item>
                                                </LinkContainer>
                                            </div>
                                        )}

                                        <NavDropdown.Item onClick={logoutHandler}>Logout</NavDropdown.Item>

                                    </NavDropdown>
                                </div>
                            ) : (
                                <LinkContainer to='/login'>
                                    <Nav.Link><i className="fas fa-user"></i>Login</Nav.Link>
                                </LinkContainer>
                            )}


                            {/*{userInfo && userInfo.isAdmin && (
                                <NavDropdown title='Admin' id='adminmenue'>
                                    <LinkContainer to='/admin/userlist'>
                                        <NavDropdown.Item>Users</NavDropdown.Item>
                                    </LinkContainer>
                                </NavDropdown>
                            )}*/}


                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </header>
    )
}

export default Header
