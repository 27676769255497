import { useEffect, useState } from 'react'
import axios from 'axios'
import { Container } from 'react-bootstrap'
import { HashRouter as Router, Route, withRouter } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Header from './components/Header'
import Footer from './components/Footer'
import HomeScreen from './screens/HomeScreen'
import LoginScreen from './screens/LoginScreen'
import RegisterScreen from './screens/RegisterScreen'
import ProfileScreen from './screens/ProfileScreen'
import UserListScreen from './screens/UserListScreen'
import UserEditScreen from './screens/UserEditScreen'
import ProjectsScreen from './screens/ProjectsScreen'
import ProjectEditScreen from './screens/ProjectEditScreen'
import ProjectScreen from './screens/ProjectScreen'
import AdminProjectsScreen from './screens/AdminProjectsScreen'
import TestScreen from './screens/TestScreen'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faCheckSquare, faCoffee } from '@fortawesome/free-solid-svg-icons'
import {requestUrl} from './constants/globalConstants'
import ProjectInfo from './components/ProjectInfo'


library.add(fab, faCheckSquare, faCoffee)

function App() {
  
  const [showContainer, setShowContainer] = useState(true)
  const userLogin = useSelector(state => state.userLogin)
  const { userInfo } = userLogin
 

  useEffect(() => {
    console.log(requestUrl);
    setShowContainer(true)
    if (!userInfo && window.location.href !== process.env.REACT_APP_BASE_URL + "#/login") {
      window.location.href = "/#/login"
      window.location.reload()
    }
  }, [userInfo])

  async function endTime() {
    const config = {
      headers: {
        'Content-type': 'application/json',
      }
    }
    const payload = {
      "id": userInfo.user_id,
    }
    await axios.post(requestUrl() + "api/settime/", payload, config)
  }

  setInterval(function () {
    if (userInfo)
      endTime()
  }, 60 * 1000);


  return (
    <Router>
      <Header />
      
      <Route path='/test' component={TestScreen} />
      <main>

        <Route
        path="/project/:id/"
        onEnter={() => {setShowContainer(false); console.log("usao")} }
        onLeave={()=> setShowContainer(true) }
        component={ ProjectScreen }
        />
    
        {!window.location.href.search("/project/") >= 0 &&
          <Container  className="py-3">
            <Route path='/' component={HomeScreen} exact />
            <Route path='/login' component={LoginScreen} />
            <Route path='/register' component={RegisterScreen} />
            <Route path='/profile' component={ProfileScreen} />
            
            <Route path='/projectinfo/:id' component={ProjectInfo} />


            <Route path='/projects' component={ProjectsScreen} />
            <Route path='/edit/:id/' component={ProjectEditScreen} />


            <Route path='/admin/userlist' component={UserListScreen} />
            <Route path='/admin/user/:id/edit' component={UserEditScreen} />
            <Route path='/admin/projects/:id' component={AdminProjectsScreen} />

          </Container>
        }

      </main>
      <Footer />
    </Router>
  );
}

export default App;
