import React, { useEffect, useState } from 'react'

import { Table, Button, Row, Col } from 'react-bootstrap'

import { useDispatch, useSelector } from 'react-redux'

import Loader from '../components/Loader'

import Message from '../components/Message'

import { listProjects } from '../actions/projectActions'

import { Link } from 'react-router-dom'
import { requestUrl } from '../constants/globalConstants'
import axios from 'axios'



function AdminProjectsScreen({ match, history }) {

    const userid = match.params.id
    const dispatch = useDispatch()
    const userLogin = useSelector(state => state.userLogin)
    const { userInfo, error, loading } = userLogin


    const [projects, setProjects] = useState([])

    async function getProjects(user_id) {
        const { data } = await axios.get(requestUrl() + "api/getalluserprojects/?user_id=" + userInfo.user_id + "&user_project_id=" + user_id + "&token=" + userInfo.token)
        setProjects(data)
    }

    useEffect(() => {
        if (!userInfo) {

            history.push('/login')

        } else if (!userInfo.role == "admin") {

            history.push("/projects")

        } else {

            getProjects(userid)

        }

    }, [dispatch, history, userInfo, userid])



    function openProject(projectid) {

        history.push("/project/" + projectid)

    }



    return (

        <div>

            <Row className='align-items-center'>

                <Col>



                    <h1>Projects</h1>

                </Col>

            </Row>


            {loading

                ? (<Loader />)

                : error

                    ? (<Message variant='danger'>{error}</Message>)

                    : (

                        <div>

                            <Table striped bordered hover responsive className='table-sm'>

                                <thead>

                                    <tr>

                                        <th>ID</th>

                                        <th>NAME</th>

                                        <th>DESCRIPTION</th>

                                        <th>CREATED AT</th>

                                        <th>VIEW PROJECT</th>

                                    </tr>

                                </thead>



                                <tbody>

                                    {projects.map(project => (

                                        <tr key={project.project_id}>

                                            <td>{project.project_id}</td>

                                            <td>{project.project_name}</td>

                                            <td>${project.project_description}</td>

                                            <td>{project.project_created_at}</td>



                                            <td>

                                                <Button variant="dark" className="btn-sm" onClick={() => openProject(project._id)}>

                                                    View Project

                                                </Button>



                                            </td>

                                        </tr>

                                    ))}

                                </tbody>

                            </Table>

                            <Link to='/admin/userlist'>

                                Users List

                            </Link>

                        </div>

                    )}

        </div>

    )

}



export default AdminProjectsScreen