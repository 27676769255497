import React, { useState, useEffect } from 'react'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
import Message from '../components/Message'
import { updateUserProfile } from '../actions/userActions'
import { USER_UPDATE_PROFILE_RESET } from '../constants/userConstants'
import {requestUrl, postConfig} from '../constants/globalConstants'
import axios from 'axios'

function ProfileScreen({ history }) {

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [message, setMessage] = useState('')

    const dispatch = useDispatch()

    const userLogin = useSelector(state => state.userLogin)
    const { error, loading, userInfo } = userLogin


    useEffect(() => {
        if (!userInfo) {
            history.push('/login')
        } else {
            setName(userInfo.first_name)
            setEmail(userInfo.email)
        }
    }, [history, userInfo])

    const submitHandler = async (e) => {
        e.preventDefault()

        if (password !== confirmPassword) {
            setMessage('Passwords do not match')
        } else {
            let payload = null
            const config = {

                    headers: {

                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${userInfo.token}`
                    }

            }
            if(password.length > 0){
                payload = {"first_name": name, "last_name": userInfo.last_name, "role": userInfo.role, "company": userInfo.company, "password": password, "user_id": userInfo.user_id}
                const { data } = await axios.post(requestUrl() + 'api/edituser/', {"first_name": name, "last_name": userInfo.last_name, "role": userInfo.role, "company": userInfo.company, "password": password, "user_id": userInfo.user_id}, config)
                
            } else {
                payload = {"first_name": name, "last_name": userInfo.last_name, "role": userInfo.role, "company": userInfo.company, "user_id": userInfo.user_id}
                const { data } = await axios.post(requestUrl() + 'api/edituser/', {"first_name": name, "last_name": userInfo.last_name, "role": userInfo.role, "company": userInfo.company, "user_id": userInfo.user_id}, config)
            }
            setMessage("Account updated succsessfully.")
        }

    }
    return (
        <Row>
            <Col md={4}></Col>
            <Col md={4}>
                <h2>User Profile</h2>

                {message && <Message variant='danger'>{message}</Message>}
                {error && <Message variant='danger'>{error}</Message>}
                {loading && <Loader />}
                <Form onSubmit={submitHandler}>

                    <Form.Group controlId='name'>
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            required
                            type='name'
                            placeholder='Enter name'
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        >
                        </Form.Control>
                    </Form.Group>

                    <Form.Group controlId='email'>
                        <Form.Label>Email Address</Form.Label>
                        <Form.Control
                            required
                            type='email'
                            placeholder='Enter Email'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            disabled
                        >
                        </Form.Control>
                    </Form.Group>

                    <Form.Group controlId='password'>
                        <Form.Label>Password</Form.Label>
                        <Form.Control

                            type='password'
                            placeholder='Enter Password'
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        >
                        </Form.Control>
                    </Form.Group>

                    <Form.Group controlId='passwordConfirm'>
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control

                            type='password'
                            placeholder='Confirm Password'
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        >
                        </Form.Control>
                    </Form.Group>

                    <Button type='submit' variant='primary'>
                        Update
                    </Button>

                </Form>
            </Col>
            <Col md={4}></Col>
        </Row>
    )
}

export default ProfileScreen