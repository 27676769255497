import { useState, useEffect } from 'react'
import '../index.css'
import '../css/burger-menu.css';
import Mapa from '../components/Mapa'
import UploadModal from '../components/UploadModal'
import {requestUrl} from '../constants/globalConstants'
import { Button } from 'react-bootstrap'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { slide as Menu } from 'react-burger-menu'
import CreateTourModal from '../components/CreateTourModal';



function ProjectScreen(props) {

    const [modalShow, setModalShow] = useState(false)
    const projectid = props.match.params.id
    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin
    const [markers, setMarkers] = useState({})
    const [projectInfo, setProjectInfo] = useState()
    const [isMenuOpen, setIsMenuOpen] = useState(null);
    const [isDrawing, setIsDrawing] = useState("None");
    const [tourModalShow, setTourModalShow] = useState(false);

    function finishUpload() {
        setModalShow(false)
    }

    function finishCreateTour(){
        setTourModalShow(false);
    }


    async function getMarkers(projectid) {

        const config = {
            headers: {

                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`

            }

        }

        const { data } = await axios.get(requestUrl() + 'api/getpanoramas/?project_id='+projectid+"&token="+userInfo.token+"&user_id="+userInfo.user_id, config)
        //console.log(data)
        setMarkers(data)
        return data
    }

    async function getProjectInfo() {

        const config = {
            headers: {

                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`

            }

        }

        const { data } = await axios.get(requestUrl() + 'api/getprojectinfo/?project_id=' + projectid + '&user_id=' + userInfo.user_id + '&token=' + userInfo.token)
        setProjectInfo(data)
        console.log(data)
    }

    useEffect(() => {
        document.body.style.overflow = "hidden"
        if (!userInfo) {

            props.history.push("/login")

        }
        getMarkers(projectid)
        getProjectInfo()
        return () => {
            document.body.style.overflow = "auto"
        }

    }, [props.match, props.history, projectid, userInfo])
    
    const showSettings = (event) => {
        event.preventDefault();
    }

    function openAddTour(){
        setIsDrawing("None");
        setModalShow(true);
    }

    return (

        <div className="h-100" style={{overflowY: "hidden"}}>
            {projectInfo && 
                <Menu right width={"15%"} isOpen={isMenuOpen}>
                    <h1>{projectInfo.project.project_name}</h1>
                    <a id="home" className="menu-item" style={{cursor: "pointer"}} onClick={()=>{props.history.push('/projectinfo/'+projectid)}}><i class="fas fa-info-circle"></i> Project Info</a>
                    <a id="about" className="menu-item" style={{cursor: "pointer"}} onClick={()=>{setIsMenuOpen(false); setModalShow(true)}}><i class="fas fa-file-import"></i> Upload Assets</a>
                    <a id="contact" className="menu-item" style={{cursor: "pointer"}} onClick={()=>{setIsMenuOpen(false); setTourModalShow(true)}}><i class="fas fa-street-view"></i> Add Tour</a>
                </Menu>
            }
            {console.log(isDrawing)}
            
            {Object.keys(markers).length !== 0 && projectInfo &&
                <Mapa geojson={{
                    "type": "FeatureCollection",
                    "features": [
                        {
                            "type": "Feature",
                            "properties": {},
                            "geometry": {
                                "type": "Polygon",
                                "coordinates": [
                                    [
                                        [
                                            0,0
                                        ],
                                        [
                                            0,0
                                        ],
                                        [
                                            0,0
                                        ],
                                        [
                                            0,0
                                        ],
                                        [
                                            0,0
                                        ]
                                    ]
                                ]
                            }
                        }
                    ]
                }} markers={markers} project={projectInfo} callback={openAddTour}></Mapa>
                }
                {/*<Button variant="primary" onClick={()=>setModalShow(true)} style= {{position: "absolute", top: "10%", left: "90%", width: "8vw"}}>Upload Assets</Button>
                <Button variant="primary" onClick={()=>{props.history.push('/projectinfo/'+projectid)}} style= {{position: "absolute", top: "17%", left: "90%", width: "8vw"}}>Project Info</Button>*/}
               
                <UploadModal
                show={modalShow}
                onHide={() => finishUpload()}
                project_id = {projectid}
                ></UploadModal>

                {Object.keys(markers).length !== 0 && projectInfo &&
                    <CreateTourModal
                    show={tourModalShow}
                    onHide={()=>finishCreateTour()}
                    markers={markers}
                    project={projectInfo}
                    >
                    </CreateTourModal>
                }
                <Link to='/projects' style={{position: "absolute", top: "10vh", left: "7vw"}}>
                Go Back
                </Link>
            
        </div>

    )

}



export default ProjectScreen