import React, { useState, useEffect } from 'react'

import axios from 'axios'

import { Link } from 'react-router-dom'

import { Form, Button, ProgressBar, Dropdown, useAccordionToggle } from 'react-bootstrap'

import { useDispatch, useSelector } from 'react-redux'

import Loader from '../components/Loader'

import Message from '../components/Message'

import FormContainer from '../components/FormContainer'

import { updateProject, listProjectDetails } from '../actions/projectActions'

import { PROJECT_UPDATE_RESET } from '../constants/projectConstants'

import { getUser } from '../actions/userActions'

import { requestUrl } from '../constants/globalConstants'





function ProjectEditScreen({ match, history }) {



    const projectId = match.params.id

    const [name, setName] = useState('')

    const [desc, setDesc] = useState('')

    const [coordinateOptions, setCoordinateOptions] = useState([])

    const [coordinateObjects, setCoordinateObjects] = useState([])

    const [options, setOptions] = useState([])

    const [epsg, setEpsg] = useState('')

    const [project, setProject] = useState(null)

    const [defaultEpsg, setDefaultEpsg] = useState()

    const [country, setCountry] = useState()


    const userLogin = useSelector(state => state.userLogin)

    const { userInfo } = userLogin


    function checkProjects(projectIds, id) {
        let ids = projectIds.split(',')
        for (var i = 0; i < ids.length; i++) {
            if (id === ids[i]) {
                return true;
            }
        }
        return false;
    }

    async function getProject() {
        const config = {
            headers: {

                'Content-Type': 'application/json',

            }
        }

        const { data } = await axios.get(requestUrl() + 'api/getproject/?user_id=' + userInfo.user_id + '&project_id=' + projectId + '&token=' + userInfo.token, config)

        setProject(data)

        return data
    }

    useEffect(() => {

        if (!userInfo) {
            history.push("/login")
        }
        /*try {
            if (!checkProjects(userDetails.project_ids, projectId) && !userDetails.isAdmin) {
                history.push("/projects")
            }
        } catch (e) {
        }*/

        if (!project) {
            getProject()
        } else {
            setName(project.project_name)
            setDesc(project.project_description)
            setCountry(project.country);
            if(project){
                getOption(project.country, project)
            }
        }  


    }, [history, userInfo, project])


    async function getOption(option, project) {
        const config = {

            headers: {
                'Content-Type': 'application/json',
            }

        }
        let list = []
        let setovao = false;
        let { data } = await axios.get("https://epsg.io/?q=" + option + "&page=1&format=json")
        let data1 = data

        for (let j = 1; j <= Math.floor(data1.number_result / 10) + 1; j++) {
            let { data } = await axios.get("https://epsg.io/?q=" + option + "&page=" + j + "&format=json")
            for (var i = 0; i < data.results.length; i++) {
                list.push(data.results[i].name + " - EPSG: " + data.results[i].code)
                if(data.results[i].code == project.epsg){
                    setovao = true;
                    setDefaultEpsg(data.results[i].name + " - EPSG: " + data.results[i].code)
                } 
                
                //object_list.push(data.results[i])
            }
        }
        if(!setovao){
            setDefaultEpsg(list[0])
        }
        setCoordinateOptions(list)
        //setCoordinateObjects(object_list)
    }

    /*const uploadFileHandler = async (e, type) => {

        if (type === "potree") {
            setUploadingType("potree")
        } else {
            setUploadingType("openlayers")
        }



        const file = e.target.files[0]


        const formData = new FormData()


        /*
        formData.append('file', file)

        formData.append('project_id', projectId)

        formData.append('type', type)

        formData.append('user_id', userInfo.id)
        
        formData.append('file', file)
        formData.append('projectid', project._id)
        formData.append('userid', userInfo.id)


        if (file) {
            setFilepath(file.name)
        }
        setUploading(true)



        try {

            const config = {

                headers: {

                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${userInfo.token}`

                },
                onUploadProgress: progressEvent => {
                    let percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
                    setUploadProgress(percentCompleted)

                }

            }




            const { data } = await axios.post(requestUrl + 'api/projects/addpointclouds/', formData, config)


            if (type === "potree") {
                setPotreeLink(data)
            } else if (type === "openlayers") {
                setOpenLayersLink(data)
            }
            setUploading(false)

            setUploadingType("None")



        } catch (error) {

            setUploading(false)

            setUploadingType("None")

        }

    }*/




    const submitHandler = async (e) => {

        const config = {
            headers: {

                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`

            }

        }

        e.preventDefault()

        let epsgList = epsg.split(" ")
        let finalEpsg = epsgList[epsgList.length - 1]

        let payload = {

            "user_id": userInfo.user_id,

            "project_id": projectId,

            "name": name,

            "description": desc,

            "epsg": finalEpsg,

            "country": country,

        }

        const { data } = await axios.post(requestUrl() + 'api/editproject/', payload, config)

        if (data) {
            history.push('/')
        }

    }



    return (

        <div>
            <Link to='/projects'>
                Go Back
            </Link>
            <FormContainer>
                <h1>Edit Project</h1>
                <Form onSubmit={submitHandler}>
                    <Form.Group controlId='name'>

                        <Form.Label>Name</Form.Label>

                        <Form.Control



                            type='name'

                            placeholder='Enter name'

                            value={name}

                            onChange={(e) => setName(e.target.value)}

                        >

                        </Form.Control>

                    </Form.Group>



                    <Form.Group controlId='desc'>

                        <Form.Label>Description</Form.Label>

                        <Form.Control

                            placeholder='Enter description'

                            value={desc}

                            onChange={(e) => setDesc(e.target.value)}

                            as="textarea"

                            rows={5}

                        >
                        </Form.Control>
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Coordinate Systems</Form.Label>
                        <br></br>
                        {project &&
                            <Form.Control as="select" name="coord1" id="coord1" onChange={(e) => { setCountry(e.target.value); getOption(e.target.value, project); }} defaultValue={project.country}>
                                <option value="Serbia">Serbia</option>
                                <option value="Saudi Arabia">Saudi Arabia</option>
                                <option value="Qatar">Qatar</option>
                                <option value="Australia">Australia</option>
                                <option value="United Arab Emirates">United Arab Emirates</option>
                            </Form.Control>
                        }
                        <br></br>
                        {
                            coordinateOptions.length > 0 && defaultEpsg && project ?
                                <Form.Control as="select" name="coord2" id="coord2" onChange={(e) => { setEpsg(e.target.value) }} defaultValue={defaultEpsg}>
                                    {
                                        coordinateOptions.map((name, index) => {
                                            return (
                                                <option key={index} value={name}>{name}</option>
                                            )
                                        })
                                    }
                                </Form.Control>
                                : <span></span>
                        }
                    </Form.Group>
                    <br></br>
                    <Button type='submit' variant='primary'>Update</Button>
                </Form>
            </FormContainer>
        </div>



    )

}



export default ProjectEditScreen

