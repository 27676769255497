import {useState, useEffect} from 'react'
import axios from 'axios'
import { requestUrl } from "../constants/globalConstants"
import { Modal, Button, Form, FormGroup, ProgressBar, Container } from "react-bootstrap"
import {useSelector} from 'react-redux'
import CustomSelect from "./CustomSelect"
import { Pannellum } from "pannellum-react";

function BubleModal(props) {

    return (
        <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        >
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
            {props.title}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {props.type === "pano" && console.log(props.imagepath)}
            {props.type==="pano" &&
                <Pannellum image={props.imagepath} hfov={61} yaw={180} pitch={10} autoLoad width="100%" height="400px" mouseZoom={false} />
            }
            {props.type==="tour" &&
                <a href={props.potreeLink} target="_blank"><Button>Go to Tour</Button></a>
            }
            {props.type==="pointcloud" && 
                <a href={props.potreeLink} target="_blank"><Button>Go to Point Cloud</Button></a>
            }
        </Modal.Body>
        <Modal.Footer>
            {props.type==="pano" &&
                <div>
                    {props.potreeLink &&
                        <a href={props.potreeLink} target="_blank"><Button>Go to Point Cloud</Button></a>
                    }
                    <Button onClick={props.deletePanorama}>Delete Pano</Button>
                </div>
            }
        </Modal.Footer>
        </Modal>
    )
}

export default BubleModal
