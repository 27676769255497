import { useState, useEffect } from 'react'
import { Table, Button, Form } from 'react-bootstrap'
import {requestUrl} from '../constants/globalConstants'
import FormContainer from './FormContainer'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import CustomTable from './CustomTable'

export default function ProjectInfo({match, history}) {

    const [project, setProject] = useState()
    
    const projectid = match.params.id
    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    async function getProject() {

        const config = {
            headers: {

                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`

            }

        }

        const { data } = await axios.get(requestUrl() + 'api/getprojectinfo/?project_id=' + projectid + '&user_id=' + userInfo.user_id + '&token=' + userInfo.token)
        setProject(data)
        console.log(data)
    }


    

    useEffect(() => {
        if (!userInfo) {

            history.push("/login")

        }

        getProject()


    }, [match])
    return (
        <div>
            <Link to={'/project/' + projectid} style={{position: "absolute", top: "10vh", left: "7vw", zIndex: "3"}}>
                Go Back
            </Link>
            {project &&
                <FormContainer>
                    <h1>Project Info</h1>
                    <div className="info"><h5>Name:</h5> <p>{project.project.project_name}</p></div><br></br>
                    <div className="info"><h5>Description:</h5> <p>{project.project.project_description}</p></div><br></br>
                    <div className="info"><h5>EPSG:</h5> <p>{project.project.epsg}</p></div><br></br>
                </FormContainer>
            }
            <br></br>
            {project && 
                <CustomTable columns={["Panorama Name", "X-ori", "Y-ori", "Z-ori", "Delete"]} data={project.pano} keys={["pano_name", "pano_x", "pano_y", "pano_z", "pano_name"]} type={"pano"}></CustomTable>
            }
            <div style={{marginTop: "3vh"}}>
                {project &&
                    <CustomTable columns={["Cloud Name", "Delete"]} data={project.point_clouds} keys={["name", "name"]} type={"cloud"}></CustomTable>
                }
            </div>
        </div>
    )
}