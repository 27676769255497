import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'


function HomeScreen({ history }) {

    const dispatch = useDispatch()

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin
    useEffect(() => {
        if (userInfo) {
            history.push('/projects')
        } else {
            history.push('/login')
        }

    }, [dispatch, history, userInfo])
    return (
        <div>

        </div>
    )
}

export default HomeScreen
