import { Modal, Button, Form, FormGroup } from "react-bootstrap"
import {useSelector} from 'react-redux'
import {useState, useEffect} from 'react'
import axios from 'axios'
import { requestUrl } from "../constants/globalConstants"

export default function CreateProjectModal(props){
    const [coordinateOptions, setCoordinateOptions] = useState([])
    const [name, setName] = useState("")
    const [description, setDescription] = useState("")
    const [epsg, setEpsg] = useState("8682")
    const [country, setCountry] = useState("Serbia")


    const userLogin = useSelector(state => state.userLogin)

    const { userInfo } = userLogin;


    async function createProject(){
        const url = requestUrl();
        const config = {
           headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }
        
        const {data} = await axios.post(url + "api/createproject/", {user_id: userInfo.user_id, name: name, description: description, epsg: epsg, country: country }, config)
        props.onHide()
    } 

    async function getOption(option) {
        const config = {

            headers: {
                'Content-Type': 'application/json',
            }

        }
        let list = []
        let object_list = {}
        let { data } = await axios.get("https://epsg.io/?q=" + option + "&page=1&format=json")

        let data1 = data

        for (let j = 1; j <= Math.floor(data1.number_result / 10) + 1; j++) {
            let { data } = await axios.get("https://epsg.io/?q=" + option + "&page=" + j + "&format=json")
            for (var i = 0; i < data.results.length; i++) {
                list.push(data.results[i].name + " - EPSG: " + data.results[i].code)
                //object_list.push(data.results[i])
            }
        }
        setEpsg(list[0])
        setCoordinateOptions(list)
        //setCoordinateObjects(object_list)
    }
    useEffect(()=>{
        getOption("Serbia")


    }, [])


    return(
        <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        >
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
            Create Project
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form>
                <Form.Group>
                    <Form.Label>Project name</Form.Label>
                    <Form.Control type="text" onChange={(e)=>setName(e.target.value)}/>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Project description</Form.Label>
                    <Form.Control as="textarea" rows={5} onChange={(e)=>setDescription(e.target.value)}/>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Coordinate Systems</Form.Label>
                    <br></br>
                    <Form.Control as="select" name="coord1" id="coord1" onChange={(e) => { getOption(e.target.value); setCountry(e.target.value) }}>
                        <option value="Serbia">Serbia</option>
                        <option value="Saudi Arabia">Saudi Arabia</option>
                        <option value="Qatar">Qatar</option>
                        <option value="Australia">Australia</option>
                        <option value="United Arab Emirates">United Arab Emirates</option>
                    </Form.Control>
                    <br></br>
                    <p></p>
                    {
                        coordinateOptions.length > 0 ?
                            <Form.Control as="select" name="coord2" id="coord2" onChange={(e)=>{setEpsg(e.target.value.split(" ")[e.target.value.split(" ").length-1])}}>
                                {
                                    coordinateOptions.map((name, index) => {
                                        return (
                                            <option key={index} value={name}>{name}</option>
                                        )
                                    })
                                }
                            </Form.Control>
                            : <span></span>
                    }
                </Form.Group>
            </Form>
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={props.onHide}>Close</Button>
            <Button onClick={createProject}>Create Project</Button>
        </Modal.Footer>
        </Modal>
    );
};