import React, { useEffect, useState } from 'react'

import { LinkContainer } from 'react-router-bootstrap'

import { Table, Button, Row, Col, Card } from 'react-bootstrap'

import { useDispatch, useSelector } from 'react-redux'

import Loader from '../components/Loader'

import Message from '../components/Message'

import Paginate from '../components/Paginate'

import { listProjects, deleteProject, createProject } from '../actions/projectActions'

import { PROJECT_CREATE_RESET } from '../constants/projectConstants'

import { getUser } from '../actions/userActions'
import axios from 'axios'

import {requestUrl} from '../constants/globalConstants'
import img1 from "../images/project.png"
import CreateProjectModal from '../components/CreateProjectModal'
import DeleteModal from '../components/DeleteModal'
import useWindowDimentions from '../hooks/getWindowSize'



function ProjectListScreen({ history, match }) {

    const dispatch = useDispatch()

    const [projects, setProjects] = useState([])
    const [modalShow, setModalShow] = useState(false)
    const [deleteShow, setDeleteShow] = useState(false)
    const [currentDelete, setCurrentDelete] = useState(0)

    const userLogin = useSelector(state => state.userLogin)

    const {height, width} = useWindowDimentions()

    const { userInfo } = userLogin

    async function getProjects() {
        let url = requestUrl()

        const { data } = await axios.get(requestUrl() + "api/getalluserprojects/?user_id=" + userInfo.user_id + "&user_project_id=" + userInfo.user_id + "&token=" + userInfo.token)
        setProjects(data)
        return data
    }



    useEffect(() => {
        if(!userInfo){
            history.push("/projects")
        }
        getProjects()
        console.log(userInfo)
        if (!userInfo) {
            history.push('/')
            console.log("radi")
        }
    }, [userInfo])


    function convertTime(time) {

        const l = time.split('T')



        const date = l[0]



        const dateSplit = date.split('-')



        const day = dateSplit[2]

        const month = dateSplit[1]

        const year = dateSplit[0]



        const timee = l[1]



        const realTime = timee.split('.')[0]



        const realRealTime = realTime.split(':')



        return day + "/" + month + "/" + year + ", " + realRealTime[0] + ":" + realRealTime[1]

    }

    function finishProject(){
        setModalShow(false)
        getProjects()
    }
    function deleteProject(){
        setDeleteShow(false)
        getProjects()
    }
    return (
        <div style={{display: "inline"}}>
            
            <Row>
                <Col md={10}></Col>
                <Col md={2}>
                    <Button variant="primary" onClick={()=>setModalShow(true)}>Create Project</Button>
                </Col>
            </Row>
            <br></br>
            <br></br>
            <Row>
            {projects.length > 0 &&
                projects.map((project, index)=>{
                    let newRow = false;
                    if(index % 4 == 0){
                        newRow = true;
                    }
                    if (width > 2000){
                        return (
                            <Col md={3} style={{textAlign: "center", marginTop: "2vh", marginLeft: "5vw"}}>
                                <Card class="card" style={{ width: '13.5vw', height: '51vh', overflow: "hidden", margin: 0}} key={project.project_id}>
                                    <Card.Img variant="top" src={img1} />
                                    <Card.Body>
                                        <Card.Title>{project.project_name}</Card.Title>
                                        <Card.Text style={{overflow: "hidden", height: "5vh"}}>
                                        {project.project_description}
                                        </Card.Text>
                                        <Row>
                                            <Col md={6}>
                                                <Button class="btn" variant="primary" onClick={() => {history.push('/project/'+project.project_id)}} style={{width: "5vw"}}>View</Button>
                                            </Col>
                                            <Col md={6}>
                                                <Button class="btn" variant="primary" onClick={() => {history.push('/edit/'+project.project_id)}} style={{width: "5vw"}}>Edit</Button>
                                            </Col>
                                        </Row>
                                        <br></br>
                                        <p><a style={{textDecoration: "underline", cursor: "pointer"}} onClick={()=> {setCurrentDelete(project); setDeleteShow(true) }}>or delete</a></p>
                                    </Card.Body>
                                </Card>
                            </Col>
                        );
                    } else if(width < 1400){
                        return (
                            <Col md={3} style={{textAlign: "center", marginTop: "2vh"}}>
                                <Card class="card" style={{ width: '17vw', height: '51vh', overflow: "hidden", margin: 0}} key={project.project_id}>
                                    <Card.Img variant="top" src={img1} />
                                    <Card.Body>
                                        <Card.Title>{project.project_name}</Card.Title>
                                        <Card.Text style={{overflow: "hidden", height: "5vh"}}>
                                        {project.project_description}
                                        </Card.Text>
                                        <Row>
                                            <Col md={6}>
                                                <Button class="btn" variant="primary" onClick={() => {history.push('/project/'+project.project_id)}} style={{width: "5vw"}}>View</Button>
                                            </Col>
                                            <Col md={6}>
                                                <Button class="btn" variant="primary" onClick={() => {history.push('/edit/'+project.project_id)}} style={{width: "5vw"}}>Edit</Button>
                                            </Col>
                                        </Row>
                                        <br></br>
                                        <p><a style={{textDecoration: "underline", cursor: "pointer"}} onClick={()=> {setCurrentDelete(project); setDeleteShow(true) }}>or delete</a></p>
                                    </Card.Body>
                                </Card>
                            </Col>
                        );
                    }
                    return (
                        <Col md={3} style={{textAlign: "center", marginTop: "2vh"}}>
                            <Card class="card" style={{ width: '13.5vw', height: '51vh', overflow: "hidden", margin: 0}} key={project.project_id}>
                                <Card.Img variant="top" src={img1} />
                                <Card.Body>
                                    <Card.Title>{project.project_name}</Card.Title>
                                    <Card.Text style={{overflow: "hidden", height: "5vh"}}>
                                    {project.project_description}
                                    </Card.Text>
                                    <Row>
                                        <Col md={6}>
                                            <Button class="btn" variant="primary" onClick={() => {history.push('/project/'+project.project_id)}} style={{width: "5vw"}}>View</Button>
                                        </Col>
                                        <Col md={6}>
                                            <Button class="btn" variant="primary" onClick={() => {history.push('/edit/'+project.project_id)}} style={{width: "5vw"}}>Edit</Button>
                                        </Col>
                                    </Row>
                                    <br></br>
                                    <p><a style={{textDecoration: "underline", cursor: "pointer"}} onClick={()=> {setCurrentDelete(project); setDeleteShow(true) }}>or delete</a></p>
                                </Card.Body>
                            </Card>
                        </Col>
                    );
                })
                
            }
            </Row>
            <CreateProjectModal
            show={modalShow}
            onHide={() => finishProject()}
            />
            <DeleteModal
            show = {deleteShow}
            onHide={() => deleteProject()}
            user_id={userInfo.user_id}
            project_id={currentDelete.project_id}
            project_name = {currentDelete.project_name}
            />
        </div> 
        
    )

}



export default ProjectListScreen