import react from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import {requestUrl} from '../constants/globalConstants'
import {Button} from 'react-bootstrap'
import '../css/main.css'
import '../css/util.css'
import '../vendor/animate/animate.css'
import '../vendor/select2/select2.min.css'
import '../vendor/perfect-scrollbar/perfect-scrollbar.css'

export default function CustomTable(props){
	const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

	async function deleteHandlerCloud(cloudid){
        const config = {
            headers: {

                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`

            }

        }

        const {data} = await axios.post(requestUrl() + 'api/deletecloud/', {"cloud_id": cloudid, "user_id": userInfo.user_id, "token": userInfo.token}, config)
        window.location.reload()
    }

    async function deleteHandlerPano(panoid){
        const config = {
            headers: {

                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`

            }

        }

        const {data} = await axios.post(requestUrl() + 'api/deletepanorama/', {"pano_id": panoid, "user_id": userInfo.user_id, "token": userInfo.token}, config)
        window.location.reload()
    }


	return(
		<div class="table100 ver1 m-b-110">
			<div class="table100-head">
				<table>
					<thead>
						<tr class="row100 head">
							{props.columns && props.columns.map((col, index) => {
								return(
									<th class={"cell100 column"+(index+1).toString()}>{col}</th>
								)}
							)}
						</tr>
					</thead>
				</table>
			</div>
			<div class="table100-body js-pscroll">
				<table>
					<tbody>
						{props.data && props.data.map((data) => {
							return (
								<tr class="row100 body">
									{props.keys && props.keys.map((key, index) => {
										return(
											<td class={"cell100 column"+(index+1).toString()}>{data[key]}</td>
										)}
									)}
								{props.type == "pano" &&
									<td class={"cell100 column5"}><Button onClick={()=> {deleteHandlerPano(data.pano_id)}}><i className='fas fa-trash fa-sm'></i></Button></td>
								}
								{props.type == "cloud" &&
									<td class={"cell100 column2"}><Button onClick={() => {deleteHandlerCloud(data.id)}}><i className='fas fa-trash fa-sm'></i></Button></td>
								}
								</tr>
							)}
						)}
					</tbody>
				</table>
			</div>
		</div>
		)
}